import { createSlice } from "@reduxjs/toolkit";
import { fetchProducts, fetchProductById, createProduct, updateProduct, deleteProduct } from "./api";
const productDbSlice = createSlice({
    name: 'productsDb',
    initialState: {
        products: [],
        productOrder: [],
        status: 'idle',
        error: null,
        selectedProduct: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProducts.pending, (state) => {
            state.status = 'loading'
        })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload.data
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(fetchProductById.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchProductById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedProduct = action.payload.data
            })
            .addCase(fetchProductById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.products.push(action.payload.data)
            })
            .addCase(createProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const index = state.products.findIndex((product) => product._id === action.payload._id);
                state.products[index] = action.payload.data;
            })
            .addCase(updateProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const deletedProductId = action.payload.data._id;
                state.products = state.products.filter((product) => product._id !== deletedProductId);
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
})

export default productDbSlice.reducer