import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductById } from "../redux/api";
import { updateProduct } from "../redux/api";
import { useParams, useNavigate } from "react-router-dom";
import AdminNav from "./AdminNavbar";
import Footer from "../components/Footer";
export default function UpdateProduct() {

    const dispatch = useDispatch();
    const productById = useSelector(state => state.products.selectedProduct)
    const { id } = useParams();
    const nav = useNavigate();
    const createProductStatus = useSelector((state) => state.products.status)

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: 0,
        // avrageRating: 0,
        photo: '',

    });
    useEffect(() => {
        dispatch(fetchProductById(id))
    }, [dispatch, id])


    useEffect(() => {
        // Update the formData when productById changes
        if (productById) {
            setFormData({
                name: productById.name || '',
                description: productById.description || '',
                price: productById.price || 0,
                // avrageRating: productById.avrageRating || 0,
                photo: productById.photo || '',
            });
        }
    }, [productById]);


    const handleChange = (field, value) => {
        setFormData(prevState => {
            return {
                ...prevState,
                [field]: value
            }
        })
        // console.log(data);
        // console.log(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            dispatch(updateProduct({ id: id, data: formData }));
            console.log(formData);
            console.log("product updated");
            nav('/dashboard')
            window.location.reload();
        } catch (error) {
            alert("Error with message", error)
        }
        // Dispatch an action to update the product with the new data

    };

    if (!productById) {
        return <h1>No Product Found</h1>
    }

    return (
        <>
            <AdminNav></AdminNav>
            <Box sx={{ maxWidth: 600, margin: 'auto', marginTop: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Update a Product
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Product Name"
                        variant="outlined"
                        margin="normal"
                        name="name"
                        value={formData.name}
                        onChange={(e) => handleChange("name", e.target.value)}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Product Description"
                        variant="outlined"
                        margin="normal"
                        name="description"
                        value={formData.description}
                        onChange={(e) => handleChange("description", e.target.value)}
                        multiline
                        rows={4}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Price"
                        variant="outlined"
                        margin="normal"
                        name="price"
                        type="number"
                        value={formData.price}
                        onChange={(e) => handleChange("price", e.target.value)}
                        required
                    />
                    {/* <TextField
                        fullWidth
                        label="Average Rating"
                        variant="outlined"
                        margin="normal"
                        name="avrageRating"
                        type="number"
                        value={formData.avrageRating}
                        onChange={(e) => handleChange("avrageRating", e.target.value)}
                    /> */}
                    <TextField
                        fullWidth
                        label="Image URL"
                        variant="outlined"
                        margin="normal"
                        name="image"
                        value={formData.photo}
                        onChange={(e) => handleChange("photo", e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={createProductStatus === 'loading'}
                        sx={{ marginTop: 2 }}
                    >
                        Update
                    </Button>
                </form>
            </Box>
            <Footer></Footer>
        </>
    );
}