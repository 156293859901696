import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProducts } from "../redux/api";
import ColorSchemesExample from "./Navbar";
import Footer from "./Footer";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { addtoCart } from "../redux/cartSlice";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
export default function ProductCardThroughApi() {
    const dispatch = useDispatch();
    const nav = useNavigate()
    const cartItems = useSelector((state) => state.cart.cart)
    const products = useSelector(state => state.products.products)
    console.log(products);
    useEffect(() => {
        dispatch(fetchProducts())
    }, [dispatch])

    const handleAddToCart = (product) => {
        const isProductInCart = cartItems.find((item) => item._id === product._id)
        if (isProductInCart) {
            console.log("Already in cart ");
            toast("Product already added")
        }
        else {
            dispatch(addtoCart(product))
            toast("Product added in Cart")

        }
    }

    return (
        <>
            <ColorSchemesExample></ColorSchemesExample>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingLeft: "120px", paddingTop: "20px" }}>
                {products.map((product) => (
                    <div key={product._id} style={{ width: '30%', marginBottom: '20px' }}>
                        <Card style={{ width: '22rem', height: "500px", marginTop: "20px", padding: "20px", marginBottom: "20px", borderColor: "black", backgroundColor: "grey" }}>
                            <Card.Img variant="top" src={product.photo} style={{ backgroundColor: "black", height: "250px" }} />
                            <Card.Body style={{ backgroundColor: "grey" }}>
                                <Card.Title>{product.name}</Card.Title>
                                <Card.Title>${product.price}</Card.Title>
                                <Button variant="primary" style={{ background: "black", borderColor: "black" }}
                                    onClick={() => nav(`/product/${product._id}`)}>Show Details</Button>
                                <Button style={{ marginLeft: "20px", backgroundColor: "black", borderColor: "black" }} onClick={() => handleAddToCart(product)}>Add To Cart</Button>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
            <Footer></Footer>
            <ToastContainer></ToastContainer>
        </>
    )
}