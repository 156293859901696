import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import SignUp from './Pages/SignUp';
import Login from './Pages/Login';
import CheckOut from './components/CheckOut';
import CheckoutSuccess from './components/CheckoutSuccess';
import SuccessPage from './components/Success';
import ForgetPassword from './components/ForgetPassword';
import ResetPassword from './components/ResetPassword';
import AdminProductCard from './Admin/AdminProductCard';
import ProductCardThroughApi from './components/ProductCardThroughApi';
import SingleProductThroughApi from './components/SingleProductThroughApi';
import ProtectedRoute from './Admin/ProtectedRoute';
import AdminSingleProduct from './Admin/AdminSingleProduct';
import ProductForm from './Admin/AdminCreateProduct';
import UpdateProduct from './Admin/AdminUpdateProduct';
function App() {
  return (

    <div>
      {/* <ColorSchemesExample></ColorSchemesExample> */}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<ProductCardThroughApi></ProductCardThroughApi>}></Route>
          <Route path='/signup' element={<SignUp></SignUp>}></Route>
          <Route path='/login' element={<Login></Login>}></Route>
          <Route path='/forgetpassword' element={<ForgetPassword></ForgetPassword>}></Route>
          <Route path='/resetpassword/:resettoken' element={<ResetPassword></ResetPassword>}></Route>
          <Route path='/product/:id' element={<SingleProductThroughApi></SingleProductThroughApi>}></Route>
          <Route path='/checkout' element={<CheckOut></CheckOut>}></Route>
          <Route path="/success" element={<SuccessPage></SuccessPage>} />
          <Route path='/checkoutsuccess' element={<CheckoutSuccess></CheckoutSuccess>}></Route>
          {/* <Route path="/dashboard" element={isLoggedIn ? <AdminDashboard /> : <Login></Login>} /> */}
          <Route path="/dashboard" element={<ProtectedRoute> <AdminProductCard /> </ProtectedRoute>} />
          <Route path="/dashboard/product/:id" element={<ProtectedRoute> <AdminSingleProduct /> </ProtectedRoute>} />
          <Route path="/dashboard/createproduct" element={<ProtectedRoute> <ProductForm /> </ProtectedRoute>} />
          <Route path="/dashboard/editproduct/:id" element={<ProtectedRoute> <UpdateProduct /> </ProtectedRoute>} />


        </Routes>
      </BrowserRouter>

    </div>

  );
}

export default App;
