import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ColorSchemesExample from "../components/Navbar";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { server } from '../server'
import Footer from "../components/Footer";
import { toast, ToastContainer } from 'react-toastify';

const theme = createTheme()
function ResetPassword() {
    const { resettoken } = useParams();

    const nav = useNavigate();
    const [newPasword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');


    // console.log("reset token", resettoken);


    const handResetPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${server}/auth/resetpassword/${resettoken}`,
                {
                    newPasword
                });
            if (response.status === 200) {
                setMessage('Password Updated Successfully');
                toast("Password Reset Successful")
                setTimeout(() => {
                    nav('/login');
                }, 2000);
                // console.log("Password Updated Successfully", response.data);
            }

        } catch (error) {
            console.log("Error", error);
            toast("Token Expired")
            setMessage(error.message);
        }
    }
    return (
        <>
            <ColorSchemesExample></ColorSchemesExample>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs" style={{ marginTop: "229px" }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>

                        <Typography component="h3" variant="h4">
                            Reset Password
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            sx={{ mt: 5 }}
                        >
                            <Grid container style={{ justifyContent: "center" }}>
                                <Grid item xs={12}>
                                    <TextField
                                        required

                                        style={{
                                            width: "100%",
                                            margin: "60px",
                                            marginBottom: 0, marginLeft: 0,
                                            marginTop: 0
                                        }}
                                        placeholder="New Password"
                                        name="Passowrd"
                                        autoComplete="Password"
                                        value={newPasword}
                                        onChange={(e) => { setNewPassword(e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handResetPassword}
                            >
                                Update Password
                            </Button>
                        </Box>
                        <Typography>{message}</Typography>
                    </Box>
                </Container>
            </ThemeProvider>
            <Footer></Footer>
            <ToastContainer></ToastContainer>
        </>
    )
}
export default ResetPassword