import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('Admintoken');

        if (!token) {
            // Redirect to login if the token is not present
            navigate('/login');
        }
    }, [navigate]);

    return <>{children}</>;
};

export default ProtectedRoute;
