import { createAsyncThunk } from "@reduxjs/toolkit";
import { server } from "../server";
import axios from "axios";
export const fetchProducts = createAsyncThunk('fetchProducts', async () => {
    try {
        const response = await axios.get(`${server}/bootcamps`);
        return response.data;
    } catch (error) {
        throw new Error(error.message)
    }
});


export const fetchProductById = createAsyncThunk('fetchProductById', async (id) => {
    try {
        const response = await axios.get(`${server}/bootcamps/${id}`);
        return response.data
    } catch (error) {
        throw new Error(error.message)
    }
})

export const createProduct = createAsyncThunk('createProduct', async (product) => {
    try {
        const token = localStorage.getItem('Admintoken');
        if (!token) {
            throw new Error("Token not found. User not authorized")
        }
        const response = await axios.post(`${server}/bootcamps`, product, {
            headers: {
                token: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.message)
    }
})



// asyncThunks.js


export const updateProduct = createAsyncThunk('updateProduct', async ({ id, data }) => {
    try {
        const token = localStorage.getItem('Admintoken');
        if (!token) {
            throw new Error("Token not found. User not authorized")
        }
        const response = await axios.put(`${server}/bootcamps/${id}`, data, {
            headers: {
                token: `Bearer ${token}`
            },
        });
        console.log(data);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});


export const deleteProduct = createAsyncThunk('deleteProduct', async (id) => {
    try {
        const token = localStorage.getItem('Admintoken');
        if (!token) {
            throw new Error("Token not found. User not authorized")
        }
        const response = await axios.delete(`${server}/bootcamps/${id}`, {
            headers: {
                token: `Bearer ${token}`
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
})