import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProducts, deleteProduct } from "../redux/api";
import AdminNav from "./AdminNavbar";
import Footer from "../components/Footer";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
export default function ProductCardThroughApi() {
    const dispatch = useDispatch();
    const nav = useNavigate()
    const products = useSelector(state => state.products.products)
    console.log(products);
    useEffect(() => {
        dispatch(fetchProducts())
    }, [dispatch])


    const handleDeleteProduct = (productId) => {
        // Dispatch the deleteProduct action with the product ID
        dispatch(deleteProduct(productId));
        console.log("product deleted");
        window.location.reload();
    };

    return (
        <>
            <AdminNav></AdminNav>
            <Button style={{ marginLeft: "120px", backgroundColor: "black", borderColor: "black", marginTop: "20px" }}
                onClick={() => nav("/dashboard/createproduct")}>Add New Product </Button>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", paddingLeft: "120px", paddingTop: "20px" }}>
                {products.map((product) => (
                    <div key={product._id} style={{ width: '30%', marginBottom: '20px' }}>
                        <Card style={{ width: '22rem', height: "500px", marginTop: "20px", padding: "20px", marginBottom: "20px", borderColor: "black", backgroundColor: "grey" }}>
                            <Card.Img variant="top" src={product.photo} style={{ backgroundColor: "black", height: "250px" }} />
                            <Card.Body style={{ backgroundColor: "grey" }}>
                                <Card.Title>{product.name}</Card.Title>
                                <Card.Title>${product.price}</Card.Title>
                                <Button variant="primary" style={{ background: "black", borderColor: "black" }}
                                    onClick={() => nav(`/dashboard/product/${product._id}`)}>Show Details</Button>
                                <Button style={{ marginLeft: "20px", backgroundColor: "black", borderColor: "black", width: "120px" }}
                                    onClick={() => nav(`/dashboard/editproduct/${product._id}`)}>Edit Product </Button>
                                <Button
                                    style={{ marginLeft: "60px", backgroundColor: "black", borderColor: "black", marginTop: "20px" }}
                                    onClick={() => handleDeleteProduct(product._id)}
                                >
                                    Delete Product
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
            <Footer></Footer>
        </>
    )
}