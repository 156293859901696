import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
function AdminNav() {
    // const cartItems = useSelector(state => state.cart.cart)
    // console.log(cartItems)
    return (
        <>
            <Navbar bg="dark" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand as={Link} to={'/dashboard'}>THE MAN</Navbar.Brand>
                    {/* <Nav className="me-auto">
                        <Nav.Link as={Link} to={'/login'}>LOGIN</Nav.Link>
                        <Nav.Link as={Link} to={'/signup'}>SIGNUP</Nav.Link>
                    </Nav>
                    <Nav className='ms-auto'>
                        <Link to='/checkout'><ShoppingCartIcon></ShoppingCartIcon></Link>
                        <p style={{ color: "white" }}>{cartItems.length}</p>
                    </Nav> */}
                </Container>
            </Navbar>

        </>
    );
}

export default AdminNav;