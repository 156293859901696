import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ColorSchemesExample from "../components/Navbar";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { server } from '../server'
import Footer from "../components/Footer";
import { toast, ToastContainer } from 'react-toastify';
const theme = createTheme()
function SignUp() {
    const navigate = useNavigate()
    const [values, setValues] = useState({
        title: '',
        email: '',
        password: '',
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
        // console.log(e.target.value);
    }

    const handleSignUp = async (e) => {
        e.preventDefault();
        // console.log(server, name, values);

        try {
            const response = await axios.post(`${server}/auth/register`, values)
            const jwt = response.data.token;
            localStorage.setItem('token', jwt);
            toast("Sign up successful");
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            toast("Sign up failed")
            console.log('Error registering user', error);
        }
    }
    return (
        <>
            <ColorSchemesExample></ColorSchemesExample>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h4">
                            Sign up
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            // onSubmit={handleSubmit}
                            sx={{ mt: 3 }}
                        >
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <TextField

                                        required
                                        fullWidth
                                        id="Name"
                                        placeholder="Full Name"
                                        name="Name"
                                        autoComplete="family-name"
                                        value={values.title}
                                        onChange={(e) => { setValues({ ...values, title: e.target.value }) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        placeholder="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, backgroundColor: "black" }}
                                onClick={handleSignUp}
                            >
                                Sign Up
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href="/login" variant="body2" style={{ color: "black" }}>
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {/* <Copyright sx={{ mt: 5 }} /> */}
                </Container>

            </ThemeProvider>
            <Footer></Footer>
            <ToastContainer></ToastContainer>
        </>
    )
}
export default SignUp