import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart, increaseQuantity, decreaseQuantity } from "../redux/cartSlice";
import ColorSchemesExample from "./Navbar";
import { loadStripe } from "@stripe/stripe-js";
import Footer from "./Footer";
import { server } from "../server";
export default function () {
    const cartItems = useSelector(state => state.cart.cart);
    console.log(cartItems);
    const subtotal = useSelector(state => {
        return state.cart.cart.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);
    });

    const handlePayment = async () => {

        const stripe = await loadStripe("pk_test_51O4KXAFIswbpNwZ5s2BNkPebZeu21XHlTLqNh1jTojte1vOseNRGWFpgwHNYwcC5vy0y8zrDAHqUXhtzrvCuss8I00Zb0wNo4Y");
        let paySession = await fetch(`${server}/stripe/payment`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                cartItems
            }),
        });

        // console.log(paySession);

        paySession = await paySession.json();
        const payResult = stripe.redirectToCheckout({
            sessionId: paySession.id,
        });

        if (payResult.error) {
            // console.log(payResult.error);
        }
    }
    const dispatch = useDispatch();
    return (
        <>
            <ColorSchemesExample></ColorSchemesExample>
            <div className="cart-container">
                <h2 style={{ textAlign: "center", marginTop: "30px" }}>Cart Items</h2>
                {cartItems.length === 0 ? (
                    <div className="cart-empty">
                        <p>Your cart is empty</p>
                    </div>
                ) : (
                    <div>
                        <div className="titles">
                            <h3 className="product-title">Product</h3>
                            <h3 className="price">Price</h3>
                            <h3 className="Quantity">Quantity</h3>
                            <h3 className="total">Total</h3>
                        </div>
                        <div className="cart-items">
                            {cartItems.map(item => {
                                return (
                                    <div className="cart-item">
                                        <div className="cart-product">
                                            <img src={item.photo} alt="" />
                                            <div>
                                                <h3>{item.name}</h3>
                                                <button onClick={() => { dispatch(removeFromCart(item._id)) }}>Remove</button>
                                            </div>
                                        </div>
                                        <div className="cart-product-price">${item.price}</div>
                                        <div className="cart-product-quantity">
                                            <button onClick={() => { dispatch(decreaseQuantity(item._id)) }}>-</button>
                                            <div className="count">{item.quantity}</div>
                                            <button onClick={() => { dispatch(increaseQuantity(item._id)) }}>+</button>
                                        </div>
                                        <div className="cart-product-total-price">
                                            ${item.price * item.quantity}
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className="cart-summary">
                            <div className="cart-checkout">
                                <div className="subtotal">
                                    <span>SubTotal</span>
                                    <span className="amount">${subtotal}</span>
                                </div>
                                <button onClick={handlePayment}>Check Out</button>

                            </div>
                        </div>
                    </div>
                )}
                {
                }
            </div>
            <Footer></Footer>
        </>
    )
}