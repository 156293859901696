import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ColorSchemesExample from "../components/Navbar";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { server } from '../server'
import Footer from "../components/Footer";
import { toast, ToastContainer } from 'react-toastify';
const theme = createTheme()
function SignUp() {
    const navigate = useNavigate()
    const [values, setValues] = useState({
        email: '',
        password: '',


    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
        // console.log(e.target.value);
    }

    const handleSignUp = async (e) => {
        e.preventDefault();
        // console.log(server, name, values);

        try {
            await axios.post(`${server}/auth/login`, values).then((res) => {
                if (res.status === 200) {
                    const jwt = res.data.token;

                    if (res.data.role === "admin") {
                        toast("Admin Login successful");
                        setTimeout(() => {
                            navigate('/dashboard');
                        }, 2000);
                        localStorage.setItem('Admintoken', jwt);
                    } else {
                        toast("User Login successful");
                        setTimeout(() => {
                            navigate('/');
                        }, 2000);
                        localStorage.setItem('token', jwt);
                        console.log("routing to home");
                    }
                }
            })


        } catch (error) {
            toast("Login Failed")
            console.log('Error registering user', error);
        }
    }
    return (
        <>
            <ColorSchemesExample></ColorSchemesExample>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs" style={{ marginTop: "125px" }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>

                        <Typography component="h1" variant="h4">
                            Login
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            // onSubmit={handleSubmit}
                            sx={{ mt: 3 }}
                        >
                            <Grid container spacing={2}>


                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        placeholder="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={values.email}
                                        onChange={handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/forgetpassword" variant="body2" style={{ color: "black" }}>
                                            Forgot Password?
                                        </Link>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, backgroundColor: "black" }}
                                onClick={handleSignUp}
                            >
                                Login
                            </Button>
                            <Grid container justifyContent={"center"}>
                                <Grid item>
                                    <Link href="/signup" variant="body2" style={{ color: "black" }}>
                                        Don't have an Account? SignUp
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {/* <Copyright sx={{ mt: 5 }} /> */}
                </Container>

            </ThemeProvider>
            <Footer></Footer>
            <ToastContainer></ToastContainer>

        </>
    )

}
export default SignUp