import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ColorSchemesExample from "../components/Navbar";
import axios from 'axios';
import { server } from '../server'
import Footer from "../components/Footer";
import { toast, ToastContainer } from 'react-toastify';

const theme = createTheme()
function ForgetPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handForgetPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${server}/auth/forgotpassword`, { email });
            setMessage(response.data.data);
            toast("Email sent")
            // console.log("email sent");
        } catch (error) {
            // console.log(error);
            setMessage('Email could not be found');
            toast("Email sending failed")
        }
    }
    return (
        <>
            <ColorSchemesExample></ColorSchemesExample>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs" style={{ marginTop: "229px" }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>

                        <Typography component="h3" variant="h4">
                            Forget Password
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            sx={{ mt: 5 }}
                        >
                            <Grid container style={{ justifyContent: "center" }}>
                                <Grid item xs={12}>
                                    <TextField
                                        required

                                        style={{
                                            width: "100%",
                                            margin: "60px",
                                            marginBottom: 0, marginLeft: 0,
                                            marginTop: 0
                                        }}
                                        placeholder="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handForgetPassword}
                            >
                                Send Email
                            </Button>
                        </Box>
                        <Typography>{message}</Typography>
                    </Box>
                </Container>
            </ThemeProvider>
            <Footer></Footer>
            <ToastContainer></ToastContainer>
        </>
    )
}
export default ForgetPassword