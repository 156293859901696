import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createProduct } from '../redux/api';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from './AdminNavbar';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';

export default function ProductForm() {
    const dispatch = useDispatch();
    const nav = useNavigate()
    const createProductStatus = useSelector((state) => state.products.status)
    const [productData, setProductData] = useState({
        name: '',
        description: '',
        price: 0,
        photo: '',
        // averageRating: 0
    })
    const handleChange = (field, value) => {
        setProductData(prevState => {
            return {
                ...prevState,
                [field]: value
            }
        })
        // console.log(data);
        // console.log(e.target.value)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            dispatch(createProduct(productData))
            console.log("product Stored in the DB");
            nav('/dashboard')
        } catch (error) {
            console.log("Error", error);
        }


    }
    return (
        <>
            <AdminNav></AdminNav>
            <Box sx={{ maxWidth: 600, margin: 'auto', marginTop: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Add a New Product
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Product Name"
                        variant="outlined"
                        margin="normal"
                        name="name"
                        value={productData.name}
                        onChange={(e) => handleChange("name", e.target.value)}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Product Description"
                        variant="outlined"
                        margin="normal"
                        name="description"
                        value={productData.description}
                        onChange={(e) => handleChange("description", e.target.value)}
                        multiline
                        rows={4}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Price"
                        variant="outlined"
                        margin="normal"
                        name="price"
                        type="number"
                        value={productData.price}
                        onChange={(e) => handleChange("price", e.target.value)}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Image URL"
                        variant="outlined"
                        margin="normal"
                        name="image"
                        value={productData.image}
                        onChange={(e) => handleChange("photo", e.target.value)}
                    />
                    {/* <TextField
                        fullWidth
                        label="Average Rating"
                        variant="outlined"
                        margin="normal"
                        name="averageRating"
                        type="number"
                        value={productData.averageRating}
                        onChange={(e) => handleChange("averageRating", e.target.value)}
                    /> */}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={createProductStatus === 'loading'}
                        sx={{ marginTop: 2 }}
                    >
                        Submit
                    </Button>
                </form>
            </Box>
            <Footer></Footer>
        </>
    );
}