import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProductById } from "../redux/api";
import { useParams, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";
import ColorSchemesExample from "./Navbar";
import Footer from "./Footer";
export default function SingleProductThroughApi() {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const productById = useSelector(state => state.products.selectedProduct)
    const params = useParams();

    useEffect(() => {
        dispatch(fetchProductById(params.id))
    }, [dispatch, params.id])

    if (!productById) {
        return <h1>No Product Found</h1>
    }

    return (
        <>
            <ColorSchemesExample></ColorSchemesExample>
            <Card sx={{
                display: "flex", marginBottom: 2, alignItems: "center",
                marginTop: "20px", padding: "10%"
            }}>
                <CardMedia
                    component="img"
                    sx={{ width: "700px", objectFit: 'cover', marginRight: "50px" }}
                    image={productById.photo}>
                </CardMedia>

                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: "22px" }}>{productById.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: "22px" }}>{productById.description}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: "22px" }}>${productById.price}</Typography>
                        </Grid>

                        <Grid item xs={6} sx={{ marginTop: "18px" }}>
                            <Button variant="contained" color="primary" style={{ background: "black", borderColor: "black" }}
                                onClick={() => { nav('/') }}>
                                Go Back</Button>

                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
            <Footer></Footer>
        </>
    )
}