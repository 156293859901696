import { createSlice } from "@reduxjs/toolkit";
const loadCartFromStorage = () => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
};
const saveCartToStorage = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
};

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        // cart: []
        cart: loadCartFromStorage(),
    },
    reducers: {
        addtoCart: (state, action) => {
            const existingItem = state.cart.find(item => item._id === action.payload);
            if (existingItem) {
                existingItem.quantity += 1;
            } else {
                const newItem = { ...action.payload, quantity: 1 }
                state.cart.push(newItem)
            }
            saveCartToStorage(state.cart);

        },
        removeFromCart: (state, action) => {
            // console.log(action.payload);
            state.cart = state.cart.filter(x => x._id !== action.payload)
            saveCartToStorage(state.cart);
        },
        increaseQuantity: (state, action) => {
            const item = state.cart.find(x => x._id === action.payload);
            if (item) {
                item.quantity += 1;
                saveCartToStorage(state.cart);
            }
        },
        decreaseQuantity: (state, action) => {
            const item = state.cart.find(x => x._id === action.payload)
            if (item && item.quantity > 1) {
                item.quantity -= 1
                saveCartToStorage(state.cart);
            }
        },
        calculateSubtotal: (state) => {
            const subtotal = state.cart.reduce((total, item) => {
                return total + item.price + item.quantity
            }, 0)
            state.total = subtotal
        }

    },
})

export default cartSlice.reducer;
export const { addtoCart, removeFromCart, increaseQuantity, decreaseQuantity } = cartSlice.actions